import React from 'react';
import './CircleCarousel.css';
import { cumulativeScoreLabel } from '../../utils/cumulativeScoreLabel/cumulativeScoreLabel';

const CircleSegment = (props: {
  index: number;
  label: string;
  value: number | undefined;
}) => {
  // Scale score percentage to adjust for overlap
  function scaledScore(score: number | undefined) {
    const OFFSET = 0.17;
    return score !== undefined ? (score * (1 - OFFSET) + OFFSET) * 100 : 0;
  }

  function segmentColor(index: number) {
    if (index < 3) {
      return '#6BE5B2';
    } else if (index < 6) {
      return '#BFA4F9';
    }
    return '#AFE1FC';
  }

  function mask(id: string) {
    return (
      <svg
        width="0"
        height="0"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id={id}>
            <path d="M256.215896,71.3391351 C265.440896,45.3196351 251.862896,16.4312351 224.937896,10.3382351 C163.256896,-3.61949491 97.868496,-3.79198491 33.437296,11.9484351 C6.61190596,18.5018351 -6.49380404,47.6265351 3.16700596,73.4956351 L84.523696,291.348035 C100.854896,335.078035 162.890896,334.561035 178.489896,290.564035 L256.215896,71.3391351 Z" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  const statMap = new Map<string, string>([
    ['environments', 'Environments'],
    ['monitoring_observation', 'Monitoring & Observability'],
    ['release_governance_automation', 'Release & Governance Automation'],
    ['golden_path', 'Golden Path'],
    ['developer_hub', 'Developer Hub'],
    ['measuring_engineering', 'Measuring Engineering'],
    ['continuous_testing', 'Continuous Testing'],
    ['test_first', 'Test First'],
    ['test_strategy', 'Test Strategy'],
  ]);

  return (
    <div
      key={props.index}
      className="item"
      data-segment={`${props.index}-slide`}
      data-slide-go="1"
      data-segment-category="1"
      data-segment-category-title="Cloud Platform"
      style={{ transform: `rotate(${props.index * 40}deg)` }}
    >
      {mask('mask')}

      <div
        className="dot"
        /* eslint-disable-next-line react/no-unknown-property */
        data-testid={`dot-element-${props.index}`}
        /* eslint-disable-next-line react/no-unknown-property */
        background-color-test={segmentColor(props.index)}
        /* eslint-disable-next-line react/no-unknown-property */
        background-percentage-test={`${(props.value ?? 0) * 100}%`}
        style={{
          background: `radial-gradient(circle at 50% 105%, ${segmentColor(
            props.index,
          )} ${`${scaledScore(props.value)}%`}, ${`${segmentColor(
            props.index,
          )}4D`} ${`${scaledScore(props.value)}%`})`,
        }}
      >
        <div className="dot-image">
          {mask('inner-mask')}

          <picture className="behave-as-bg">
            <img
              data-src=""
              data-srcset=""
              width="300"
              height="300"
              className="lazyloaded"
              srcSet=""
              src=""
              style={{ opacity: 0 }}
              alt="Background for a segment of the QCE circle."
            />
          </picture>
        </div>

        <div
          className="dot-content"
          style={{ transform: `rotate(${-props.index * 40 + 40}deg)` }}
        >
          <div
            className="dot-pre-heading"
            style={{
              textTransform: 'capitalize',
              color: 'white',
              textShadow:
                '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000',
              fontWeight: 'bold',
            }}
          >
            {statMap.get(props.label)}
          </div>
          <div
            className="dot-heading"
            style={{
              color: 'white',
              textShadow:
                '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000',
            }}
          >
            {segmentScoreLabel(props.value)}
          </div>
        </div>
      </div>
    </div>
  );
};

export function segmentScoreLabel(value: number | undefined) {
  return value !== undefined ? `${Math.round(value * 100)}%` : 'N/A';
}

export const CircleCarousel = (props: { stats: object }) => {
  const stats = props.stats;
  const statList = [
    'environments',
    'monitoring_observation',
    'release_governance_automation',
    'golden_path',
    'developer_hub',
    'measuring_engineering',
    'continuous_testing',
    'test_first',
    'test_strategy',
  ];

  return (
    <div
      data-testid="qce-circle-carousel"
      className="row justify-content-center align-items-start block-approach-wheel"
    >
      <div
        className="circle-container"
        style={{ display: 'grid', justifyContent: 'center' }}
      >
        <div className="circle-carousel" data-speed="1000" data-autoplay="2500">
          <div className="slides">
            <div className="slide">
              <h2>{cumulativeScoreLabel(stats)}</h2>
              <div className="slide-copy">Cumulative Score</div>
            </div>
          </div>

          <div className="pagination">
            {statList.map((stat: string, index: number) => (
              <CircleSegment
                index={index}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value={(stats as any)[stat]}
                label={stat}
                key={stat}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
