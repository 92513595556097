export function calcScore(stats: object) {
  const sum = Object.values(stats).reduce((a, b) => a + (b ?? 0), 0);
  const count = Object.values(stats).reduce(
    (a, b) => a + (b !== undefined ? 1 : 0),
    0,
  );
  return sum / Math.max(count, 1);
}

export function formatScoreValue(score: number, decimal = 0): number {
  const multiplier = Math.pow(10, decimal);
  return Math.round(score * 100 * multiplier) / multiplier;
}

export function formatScore(score: number, decimal = 0): string {
  return `${formatScoreValue(score, decimal)}%`;
}

export function cumulativeScoreLabel(stats: object, decimal = 0) {
  return formatScore(calcScore(stats), decimal);
}
