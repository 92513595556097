import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const CRLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#1c232c',
      },
      secondary: {
        main: '#6be5b2',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#f7f7f7',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#1c232c',
        indicator: '#6be5b2',
        color: '#f7f7f7',
        selectedColor: '#f7f7f7',
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: ['#006f62', '#3cdbc0'],
      shape: shapes.wave,
    }),
    documentation: genPageTheme({
      colors: ['#006f62', '#3cdbc0'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#006f62', '#3cdbc0'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#006f62', '#3cdbc0'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#006f62', '#3cdbc0'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#006f62', '#3cdbc0'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#006f62', '#3cdbc0'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#006f62', '#3cdbc0'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#006f62', '#3cdbc0'], shape: shapes.wave }),
  },
});

export const CRDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#f7f7f7',
      },
      secondary: {
        main: '#6be5b2',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#1c232c',
        paper: '#262f3c',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#1c232c',
        indicator: '#6be5b2',
        color: '#f7f7f7',
        selectedColor: '#f7f7f7',
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#1c232c'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#1c232c'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#1c232c'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#1c232c'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#1c232c'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#1c232c'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#1c232c'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#1c232c'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#1c232c'], shape: shapes.wave }),
  },
});
