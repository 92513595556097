import { makeStyles } from '@material-ui/core/styles';
import { Article, Code, Extension } from '@mui/icons-material';
import React from 'react';
import azureIcon from './icons/azure.png';
import awsIcon from './icons/aws.png';
import gcpIcon from './icons/gcp.png';
import githubIcon from './icons/github.png';
import slackIcon from './icons/slack.png';
import notionIcon from './icons/notion.png';
import canvaIcon from './icons/canva.png';
import udemyIcon from './icons/udemy.png';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://portal.azure.com',
    label: 'Azure Portal',
    icon: (
      <img
        src={azureIcon}
        alt="Azure Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://aws.amazon.com/console',
    label: 'AWS Console',
    icon: (
      <img
        src={awsIcon}
        alt="AWS Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://console.cloud.google.com',
    label: 'GCP Console',
    icon: (
      <img
        src={gcpIcon}
        alt="GCP Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://github.com/clear-route',
    label: 'GitHub',
    icon: (
      <img
        src={githubIcon}
        alt="GitHub Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://clear-route.slack.com/ssb/redirect',
    label: 'CR Slack',
    icon: (
      <img
        src={slackIcon}
        alt="Slack Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://www.notion.so/clearroute',
    label: 'CR Notion',
    icon: (
      <img
        src={notionIcon}
        alt="Notion Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://www.canva.com/',
    label: 'Canva',
    icon: (
      <img
        src={canvaIcon}
        alt="Canva Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://clearroute.udemy.com/organization/home/',
    label: 'CR Udemy',
    icon: (
      <img
        src={udemyIcon}
        alt="Udemy Icon"
        style={{ maxWidth: '2rem', maxHeight: '2rem' }}
      />
    ),
  },
  {
    url: 'https://dev.azure.com/clearroute/Backstage/_git/backstage',
    label: 'Source Code',
    icon: <Code />,
  },
  {
    url: 'https://john.azure.clearroute.io/docs/default/component/idp-playbook',
    label: 'Docs',
    icon: <Article />,
  },
  {
    url: 'https://backstage.io/plugins',
    label: 'Plugins Directory',
    icon: <Extension />,
  },
];
