import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { metricPageRouteRef, rootRouteRef, teamPageRouteRef } from './routes';

export const qceCirclePlugin = createPlugin({
  id: 'qce-circle',
  routes: {
    root: rootRouteRef,
    teamPage: teamPageRouteRef,
  },
});

export const QceCirclePage = qceCirclePlugin.provide(
  createRoutableExtension({
    name: 'QceCirclePage',
    component: () => import('./components/Home').then(m => m.Home),
    mountPoint: rootRouteRef,
  }),
);

export const TeamPage = qceCirclePlugin.provide(
  createRoutableExtension({
    name: 'TeamPage',
    component: () => import('./components/Team').then(m => m.Team),
    mountPoint: teamPageRouteRef,
  }),
);

export const MetricPage = qceCirclePlugin.provide(
  createRoutableExtension({
    name: 'MetricPage',
    component: () => import('./components/Metric').then(m => m.Metric),
    mountPoint: metricPageRouteRef,
  }),
);
