import React from 'react';
import {
  Table,
  TableColumn,
  Progress,
  ResponseErrorPanel,
  LinearGauge,
} from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { Typography, useTheme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { cumulativeScoreLabel } from '../../utils/cumulativeScoreLabel/cumulativeScoreLabel';
import axios from 'axios';
import { CircleCarousel } from '../CircleCarousel';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export type Stats = {
  environments?: number;
  monitoring_observation?: number;
  release_governance_automation?: number;
  golden_path?: number;
  developer_hub?: number;
  measuring_engineering?: number;
  continuous_testing?: number;
  test_first?: number;
  test_strategy?: number;
};

type Team = {
  name: string;
  stats: Stats;
  id: number;
};

type DenseTableProps = {
  teams: Team[];
};

type RowData = {
  name: string;
  score: string;
  stats: Stats;
  id: number;
};

export const DenseTable = ({ teams }: DenseTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const columns: TableColumn<RowData>[] = [
    {
      title: 'Team',
      field: 'name',
      headerStyle: { ...theme.typography.h3 },
      cellStyle: { ...theme.typography.body1 },
    },
    {
      title: 'Score',
      field: 'score',
      headerStyle: { ...theme.typography.h3 },
      cellStyle: { ...theme.typography.body1 },
      defaultSort: 'desc',
    },
    {
      title: '',
      field: 'score',
      headerStyle: { ...theme.typography.h3 },
      cellStyle: { ...theme.typography.body1 },
      render: (rowData: RowData) => {
        return (
          <LinearGauge
            value={
              parseFloat(rowData.score.substring(0, rowData.score.length - 1)) /
              100
            }
            width="thin"
          />
        );
      },
    },
  ];
  const data = teams.map(team => {
    return {
      name: team.name,
      score: cumulativeScoreLabel(team.stats, 1),
      stats: team.stats,
      id: team.id,
    };
  });
  return (
    <Table
      title="Quality Cloud Engineering Scores"
      options={{ search: false, paging: false }}
      columns={columns}
      data={data}
      onRowClick={(_events, rowData: RowData | undefined) => {
        if (rowData === undefined) {
          return;
        }
        navigate('/team-page', {
          state: {
            name: rowData.name,
            stats: rowData.stats,
            teamId: rowData.id,
          },
        });
      }}
    />
  );
};

function addStats(a: Stats, b: Stats): Stats {
  const result = { ...a };
  for (const key of Object.keys(result)) {
    if (key in b) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result as any)[key] += (b as any)[key];
    }
  }
  return result;
}

export const OrgScore = () => {
  const config = useApi(configApiRef);
  const baseUrl = config.getString('backend.baseUrl');

  const { value, loading, error } = useAsync(async (): Promise<
    Stats | undefined
  > => {
    // Would use fetch in a real world example
    const data = (
      await axios.get(
        `${
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          baseUrl
        }/api/admin/v1/team/summary`,
      )
    ).data;
    if (data === undefined || data.length === 0) {
      return undefined;
    }
    const average = data.reduce(
      (acc: Stats, x: Team) => addStats(acc, x.stats),
      {
        environments: 0,
        monitoring_observation: 0,
        release_governance_automation: 0,
        golden_path: 0,
        developer_hub: 0,
        measuring_engineering: 0,
        continuous_testing: 0,
        test_first: 0,
        test_strategy: 0,
      },
    );
    for (const key of Object.keys(average)) {
      average[key] /= data.length;
    }
    return average;
  }, []);
  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  if (value === undefined) {
    return <Typography>No Data</Typography>;
  }
  return <CircleCarousel stats={value} />;
};

export const OrgTable = () => {
  const config = useApi(configApiRef);
  const baseUrl = config.getString('backend.baseUrl');

  const { value, loading, error } = useAsync(async (): Promise<Team[]> => {
    // Would use fetch in a real world example
    return (
      await axios.get(
        `${
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          baseUrl
        }/api/admin/v1/team/summary`,
      )
    ).data;
  }, []);
  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  return <DenseTable teams={value || []} />;
};
