import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'qce-circle',
});

export const teamPageRouteRef = createRouteRef({
  id: 'team-page',
});

export const metricPageRouteRef = createRouteRef({
  id: 'metric-page',
});
