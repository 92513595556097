import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="34"
      viewBox="0 0 186 34"
      fill="none"
    >
      <path
        d="M19.8775 19.3041L7.91624 25.1448V14.8137C7.91624 13.8795 8.47717 13.0395 9.33832 12.6705L23.9699 6.43731L25.7238 7.53637C25.9213 7.66197 26.182 7.51281 26.1741 7.2773L25.9055 0.557377C25.8897 0.243362 25.6369 0 25.3209 0H14.6948C14.4025 0 14.2919 0.384669 14.5368 0.541676L15.7693 1.31886L1.42208 7.42646C0.560931 7.79543 0 8.63542 0 9.56961V28.0808C0 28.6617 0.292316 29.2113 0.782143 29.5331L7.07879 33.7017C7.60022 34.0471 8.27176 34.0942 8.83269 33.8194L27.1854 24.8543C27.9991 24.4539 28.0703 23.3392 27.3197 22.8368L22.216 19.4611C21.5208 18.9979 20.628 18.9351 19.8775 19.3041Z"
        fill="#00E8AF"
      />
      <path
        d="M9.35419 33.5368L27.1855 24.8229C27.9992 24.4225 28.0703 23.3078 27.3198 22.8053L22.2161 19.4297C21.5209 18.9665 20.6281 18.9116 19.8776 19.2805L7.92421 25.1212V31.7548C7.92421 31.7548 7.86101 33.8037 9.35419 33.5368Z"
        fill="#009167"
      />
      <path
        d="M37.9697 16.2503C37.9697 10.5823 42.2834 6.76703 47.9638 6.76703C52.4196 6.76703 54.7977 9.2713 56.0143 11.6892L51.9219 13.6126C51.3136 12.1524 49.7335 10.9434 47.9638 10.9434C44.9458 10.9434 42.8127 13.2514 42.8127 16.2503C42.8127 19.2491 44.9458 21.5571 47.9638 21.5571C49.7335 21.5571 51.3136 20.3482 51.9219 18.888L56.0143 20.8114C54.8214 23.1743 52.4196 25.7335 47.9638 25.7335C42.2834 25.7335 37.9697 21.8869 37.9697 16.2503Z"
        fill="#F7F7F7"
      />
      <path
        d="M57.9497 25.4038V7.06534H62.208V25.4038H57.9497Z"
        fill="#F7F7F7"
      />
      <path
        d="M64.4204 18.7467C64.4204 14.9 67.3515 11.7913 71.5308 11.7913C75.4889 11.7913 78.3647 14.6802 78.3647 19.1863V20.097H68.8526C69.1054 21.3373 70.2667 22.4599 72.2023 22.4599C73.1425 22.4599 74.7779 22.0439 75.5205 21.3295L77.3218 23.9672C75.9945 25.1526 73.7824 25.7257 71.7283 25.7257C67.6043 25.7335 64.4204 23.0644 64.4204 18.7467ZM71.5387 15.0649C69.6821 15.0649 68.9632 16.3837 68.7973 17.3493H74.3039C74.1932 16.4151 73.498 15.0649 71.5387 15.0649Z"
        fill="#F7F7F7"
      />
      <path
        d="M88.3585 25.4038V24.085C87.5289 25.0741 85.9252 25.7335 84.2108 25.7335C82.1645 25.7335 79.6206 24.3597 79.6206 21.3609C79.6206 18.1422 82.1645 17.0981 84.2108 17.0981C85.9805 17.0981 87.5605 17.6476 88.3585 18.6368V17.0432C88.3585 15.8892 87.363 15.1198 85.6486 15.1198C84.3214 15.1198 82.9388 15.6144 81.8564 16.525L80.3316 13.8559C82.046 12.3957 84.3451 11.7913 86.3913 11.7913C89.5989 11.7913 92.6168 12.9767 92.6168 17.0981V25.4038H88.3585ZM88.3585 21.9968V20.8113C87.9161 20.2069 86.9206 19.8772 85.9805 19.8772C84.8428 19.8772 83.8473 20.3717 83.8473 21.4158C83.8473 22.4599 84.8428 22.9545 85.9805 22.9545C86.9206 22.9545 87.9161 22.6248 88.3585 21.9968Z"
        fill="#F7F7F7"
      />
      <path
        d="M95.7222 25.4038V12.121H99.9805V13.746C100.865 12.6705 102.556 11.7677 104.16 11.7677V15.8342C103.907 15.7793 103.552 15.7243 103.109 15.7243C102.003 15.7243 100.534 16.1953 99.9805 16.9333V25.4038H95.7222Z"
        fill="#F7F7F7"
      />
      <path
        d="M117.275 25.4038L114.178 19.2413H111.744V25.4038H106.988V7.06534H116.232C120.324 7.06534 122.655 9.75802 122.655 13.1729C122.655 16.3602 120.688 18.0637 118.973 18.6761L122.734 25.4117H117.275V25.4038ZM117.796 13.1415C117.796 11.9012 116.745 11.1868 115.497 11.1868H111.736V15.1198H115.497C116.745 15.1198 117.796 14.4368 117.796 13.1415Z"
        fill="#00E8AF"
      />
      <path
        d="M123.444 18.7467C123.444 15.0099 126.186 11.7913 130.721 11.7913C135.311 11.7913 138.029 15.0099 138.029 18.7467C138.029 22.4835 135.319 25.7335 130.721 25.7335C126.186 25.7335 123.444 22.4913 123.444 18.7467ZM133.652 18.7467C133.652 16.9882 132.601 15.528 130.721 15.528C128.864 15.528 127.845 16.9882 127.845 18.7467C127.845 20.5366 128.872 21.9889 130.721 21.9889C132.601 21.9968 133.652 20.5366 133.652 18.7467Z"
        fill="#00E8AF"
      />
      <path
        d="M149.287 25.4038V23.8102C148.457 24.7444 146.909 25.7336 144.665 25.7336C141.624 25.7336 140.265 24.03 140.265 21.5807V12.121H144.523V19.8458C144.523 21.4394 145.353 21.9889 146.735 21.9889C147.952 21.9889 148.781 21.3295 149.279 20.6936V12.121H153.537V25.4038H149.287Z"
        fill="#00E8AF"
      />
      <path
        d="M157.535 21.8319V15.8107H155.323V12.1288H157.535V8.50195H161.77V12.1288H164.479V15.8107H161.77V20.6465C161.77 21.4158 162.212 21.9968 162.962 21.9968C163.437 21.9968 163.903 21.8319 164.045 21.667L164.874 24.8543C164.345 25.3489 163.294 25.7335 161.722 25.7335C159.004 25.7335 157.535 24.3911 157.535 21.8319Z"
        fill="#00E8AF"
      />
      <path
        d="M165.728 18.7467C165.728 14.9 168.659 11.7913 172.838 11.7913C176.796 11.7913 179.672 14.6802 179.672 19.1863V20.097H170.152C170.405 21.3373 171.566 22.4599 173.502 22.4599C174.442 22.4599 176.077 22.0439 176.82 21.3295L178.621 23.9672C177.294 25.1526 175.082 25.7257 173.028 25.7257C168.911 25.7335 165.728 23.0644 165.728 18.7467ZM172.838 15.0649C170.981 15.0649 170.262 16.3837 170.096 17.3493H175.603C175.5 16.4151 174.805 15.0649 172.838 15.0649Z"
        fill="#00E8AF"
      />
    </svg>
  );
};

export default LogoFull;
